@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

.spinner {
    border: 4px solid #ffffff;
    border-top: 4px solid #181818;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}