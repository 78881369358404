@import "default/color_variable";
@import "default/typography";
@import "common/general";
@import "common/slider";
@import "common/video-modal";
@import "common/sidebar";



// of chhose us 
//start
.orange {
    color: #ff4a17;
  }
// .slick-track {
//     padding: 5.5rem 0;
//     // padding-bottom: 1.5rem;
//     height:451px;
//     justify-content: center;
//     display: flex;
//   }

//   .slick-slide{
//     margin: 0 27px;
//   }

// // //   /* the parent */
//   .slick-list{
//     padding: 2.5rem 0;
//     height:451px;
//     margin: 0 -450px;
// }


// .slick-track {
//     padding: 5.5rem 0;
//     // padding-bottom: 1.5rem;
//     height:451px;
//     justify-content: center;
//     display: flex;
//   }

//   .slick-slide{
//     margin: 0 27px;
//   }

// //   /* the parent */
//   .slick-list{
//     padding: 2.5rem 0;
//     height:451px;
//     margin: 0 -450px;
// }
  
//   .center .slick-center .project{

//     transform: scale(1.3);
//     z-index: 10000;
//     transition: all 400ms ease-in-out;
//     pointer-events: all;
//     opacity: 1;
//   }
  
//   .center .project{
//     opacity: 0.7;
//     pointer-events: none;
//   }
  
//   @media(max-width: 990px){
//     .center .slick-center .project{
//       transform: scale(1);
//     }
  
//     .center .project{
//       opacity: 1;
//       pointer-events: all;
//     }


//   }
  //end of choose us