// src/CareerPage.scss

.career-page {
    font-family: 'Arial, sans-serif';
    padding: 20px;
    color: #333;
    background-color: #f7f7f7;
  
    .career-header {
      text-align: center;
      margin-bottom: 40px;
  
      h1 {
        font-size: 2.5rem;
        color: #0073e6;
      }
  
      p {
        font-size: 1.2rem;
        color: #555;
      }
    }
  
    .career-listings {
      max-width: 900px;
      margin: 0 auto;
  
      h2 {
        text-align: center;
        font-size: 2rem;
        color: #333;
        margin-bottom: 20px;
      }
  
      .job-card {
        background-color: white;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
        h3 {
          font-size: 1.5rem;
          color: #0073e6;
          margin-bottom: 10px;
        }
  
        p {
          font-size: 1rem;
          color: #666;
          margin-bottom: 20px;
        }
  
        .apply-btn {
          display: inline-block;
          padding: 10px 20px;
          background-color: #0073e6;
          color: white;
          text-decoration: none;
          border-radius: 5px;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #005bb5;
          }
        }
      }
    }
  
    .career-footer {
      text-align: center;
      margin-top: 50px;
      font-size: 0.9rem;
      color: #999;
    }
  }
  